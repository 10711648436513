/**
 * @author Sky Lin
 * @date 2019/12/13
 */
import React, { useState, useEffect } from 'react';
import { loadConfig } from '../service/api';
import styles from '../assets/css/business.module.scss';
import Layout from '../components/layout/index';
import HistoryStep from '../components/historyStep/index';
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.css';

const {
  banner,
  tabMenu,
  menuItemActive,
  menuItem,
  mainPage,
  secondPage,
  listPage,
  pageMenu,
  pageContent,
  fixedNav,
  section,
  wrapper,
  imgList,
  imgListRow,
  gray,
  slideSection,
  slideBox,
} = styles;

const getBusiness = async () => {
  try {
    const conf = await loadConfig();
    const { businessPage } = conf.data || {};
    return businessPage || {};
  } catch (e) {
    console.error(e);
  }
};

const TabMenu = props => {
  return (
    <div className={tabMenu}>
      {props.menu.map((item, index) => (
        <div
          className={props.tabIndex === index ? menuItemActive : menuItem}
          key={index}
          onClick={() => props.tabHandle(index)}
        >
          <span>{item}</span>
        </div>
      ))}
    </div>
  );
};

const PageMain = props => {
  const [content, setContent] = useState([]);
  const [section01, setSection01] = useState(null);
  const [section02, setSection02] = useState(null);
  const [section03, setSection03] = useState(null);
  const [section04, setSection04] = useState(null);
  const [navIndex, setIndex] = useState(0);

  useEffect(() => {
    if (!!props.data) {
      setContent(props.data.content);
      setSection01(props.data.content[0]);
      setSection02(props.data.content[1]);
      setSection03(props.data.content[2]);
      setSection04(props.data.content[3]);
    }
  }, [props.data]);

  return (
    <div className={mainPage}>
      <div className={fixedNav}>
        {content.map((item, index) => (
          <a
            className={navIndex === index ? menuItemActive : ''}
            key={index}
            href={'#' + index}
            onClick={() => setIndex(index)}
          >
            {item.menuTitle}
          </a>
        ))}
      </div>
      <div id="0">
        <SectionOne data={section01} />
      </div>
      <div id="1" className={gray}>
        <SectionTwo data={section02} />
      </div>
      <div id="2">
        <SectionThree data={section03} />
      </div>
      <div id="3" className={gray}>
        <SectionFour data={section04} />
      </div>
    </div>
  );
};

const SectionOne = props => {
  const [title, setTitle] = useState('');
  const [history, setHistory] = useState([]);
  const [img, setImg] = useState();
  const [imgs, setImgs] = useState([]);

  useEffect(() => {
    if (!!props.data) {
      setTitle(props.data.title);
      setHistory(props.data.history);
      setImg(props.data.imgList.slice(0, 1));
      setImgs(props.data.imgList.slice(1));
    }
  }, [props.data]);

  return (
    <div className={section}>
      <h2>{title}</h2>
      <div className={wrapper}>
        <div>
          <img src={img} alt="pic" />
        </div>
        <div>
          {history.map((item, index) => (
            <HistoryStep
              key={index}
              isEnd={index === history.length - 1}
              timing={item.timing}
              introduction={item.introduction}
            ></HistoryStep>
          ))}
        </div>
      </div>
      <div className={imgList}>
        {imgs.map((item, index) => (
          <img src={item} key={index} alt="pic" />
        ))}
      </div>
    </div>
  );
};

const SectionTwo = props => {
  const [title, setTitle] = useState('');
  const [description, setDes] = useState('');
  const [imgs, setImgs] = useState([]);

  useEffect(() => {
    if (!!props.data) {
      setTitle(props.data.title);
      setDes(props.data.description);
      setImgs(props.data.imgList);
      setTimeout(() => {
        new Swiper('.swiper-container', {
          watchSlidesProgress: true,
          slidesPerView: 'auto',
          centeredSlides: true,
          loop: true,
          loopedSlides: 3,
          on: {
            progress: function(progress) {
              for (let i = 0; i < this.slides.length; i++) {
                const slide = this.slides.eq(i);
                const slideProgress = this.slides[i].progress;
                let modify = 1;
                if (Math.abs(slideProgress) > 1) {
                  modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                }
                const translate = slideProgress * modify * 100 + 'px';
                const scale = 1 - Math.abs(slideProgress) / 3;
                const zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                slide.transform(
                  'translateX(' + translate + ') scale(' + scale + ')'
                );
                slide.css('zIndex', zIndex);
                slide.css('opacity', 1);
                if (Math.abs(slideProgress) > 1) {
                  slide.css('opacity', 0);
                }
              }
            },
            setTransition: function(transition) {
              for (let i = 0; i < this.slides.length; i++) {
                this.slides.eq(i).transition(transition);
              }
            },
          },
        });
      });
    }
  }, [props.data]);

  return (
    <div className={slideSection}>
      <div className={section}>
        <h2>{title}</h2>
        <h6>{description}</h6>
      </div>
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {imgs.map((item, index) => (
            <div className={`${slideBox} swiper-slide`} key={index}>
              <div>
                <img src={item} alt="pic" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const SectionThree = props => {
  const [title, setTitle] = useState('');
  const [description, setDes] = useState('');
  const [imgs, setImgs] = useState([]);

  useEffect(() => {
    if (!!props.data) {
      setTitle(props.data.title);
      setDes(props.data.description);
      setImgs(props.data.imgList);
    }
  }, [props.data]);

  return (
    <div className={section}>
      <h2>{title}</h2>
      <h6>{description}</h6>
      <div className={imgListRow}>
        {imgs.map((item, index) => (
          <img src={item} key={index} alt="pic" />
        ))}
      </div>
    </div>
  );
};

const SectionFour = props => {
  const [title, setTitle] = useState('');
  const [description, setDes] = useState('');
  const [list, setList] = useState([]);

  useEffect(() => {
    if (!!props.data) {
      setTitle(props.data.title);
      setDes(props.data.description);
      setList(props.data.list);
    }
  }, [props.data]);

  return (
    <div className={section}>
      <h2>{title}</h2>
      <h6>{description}</h6>
      <div className={imgListRow}>
        {list.map((item, index) => (
          <div key={index}>
            <p>{item.description}</p>
            <img src={item.img} alt="pic" />
          </div>
        ))}
      </div>
    </div>
  );
};

const PageSecond = props => {
  const [img, setImg] = useState(null);
  const [name, setName] = useState(null);
  const [wechat, setWechat] = useState(null);
  const [email, setEmail] = useState(null);
  const [contentIndex, setIndex] = useState(props.pageSecondIndex);
  const [content, setContent] = useState([]);

  useEffect(() => {
    if (!!props.data) {
      setContent(props.data.content);
      setImg(props.data.content[contentIndex].img);
      setName(props.data.content[contentIndex].personInfo.name);
      setWechat(props.data.content[contentIndex].personInfo.wechat);
      setEmail(props.data.content[contentIndex].personInfo.email);
    }
  }, [props.data, contentIndex]);

  return (
    <div className={secondPage}>
      <div className={pageMenu}>
        {content.map((item, index) => (
          <div
            className={
              props.pageSecondIndex === index ? menuItemActive : menuItem
            }
            key={index}
            onClick={() => {
              props.tabHandle(index);
              setIndex(index);
            }}
          >
            <label>{item.label}</label>
            <span>{item.description}</span>
          </div>
        ))}
      </div>
      <div className={pageContent}>
        <img src={img} alt="pic" />
        <div>
          <span>{name}</span>
          <span>{wechat}</span>
          <span>{email}</span>
        </div>
      </div>
    </div>
  );
};

const PageList = props => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    if (!!props.data) {
      setContent(props.data.content);
    }
  }, [props.data]);

  return (
    <div className={listPage}>
      <div>
        {content.map((item, index) => (
          <div key={index}>
            <label>{item.label}</label>
            <span>{item.description}</span>
            <img src={item.imgCase} alt="imgCase" />
          </div>
        ))}
      </div>
    </div>
  );
};

const TabPage = props => {
  const [tab01, setTab01] = useState(null);
  const [tab02, setTab02] = useState(null);
  const [menu, setMenu] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [pageSecondIndex, setPageSecondIndex] = useState(0);

  useEffect(() => {
    if (!!props.data) {
      setMenu([
        props.data.tab01.title,
        props.data.tab02.title,
        props.data.tab02.titleCase,
      ]);
      setTab01(props.data.tab01);
      setTab02(props.data.tab02);
    }
  }, [props.data]);

  return (
    <div>
      <TabMenu
        menu={menu}
        tabIndex={tabIndex}
        tabHandle={index => setTabIndex(index)}
      />
      {tabIndex === 0 && <PageMain data={tab01} />}
      {tabIndex === 1 && (
        <PageSecond
          pageSecondIndex={pageSecondIndex}
          data={tab02}
          tabHandle={index => setPageSecondIndex(index)}
        />
      )}
      {tabIndex === 2 && <PageList data={tab02} />}
    </div>
  );
};

export default () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      const business = await getBusiness();
      setData(business);
    })();
  }, []);

  return (
    <Layout>
      <div className={banner}></div>
      <TabPage data={data} />
    </Layout>
  );
};
