/**
 * @author CHEN GUANGPENG
 * @date 2019/12/16
 */
import React from 'react';
import styles from './index.module.css';

export default props => {
  const { stepContainer, dot, line, title, content } = styles;
  const { isEnd = false, introduction, timing } = props;
  return (
    <div className={stepContainer}>
      <div className={dot}></div>
      {!isEnd && <div className={line}></div>}
      <h1 className={title}>{timing}</h1>
      <p className={content}>{introduction}</p>
    </div>
  );
};
